import React from "react";

import image1 from "../media/1.jpg";
import image2 from "../media/2.jpg";
import image3 from "../media/3.jpg";

function ImageContainer() {
    return (
        <div className="image-container">
            <img src={image3} alt="Image 3" className="image" />
            <img src={image1} alt="Image 1" className="image" />
            <img src={image2} alt="Image 2" className="image" />
        </div>
    );
}

export default ImageContainer;

