import React from 'react';
import Header from './components/Header';
import ImageContainer from "./components/ImageContainer";
import Ticker from './components/Ticker';
import Nav from './components/Nav';
import About from './components/About';
import Platform from './components/Platform';
import Gallery from './components/Gallery';
import Responsibilities from './components/Responsibilities';
import Approach from './components/Approach';
import Integrations from './components/Integrations';
import { siteInfo } from './misc/information';

function App() {

    return (
      <div className="App">
        <div className="home-container">
          <Header image={siteInfo.header.image} alt={siteInfo.header.alt} />
          <ImageContainer />
          {/*<Ticker 
            ticker1Text={siteInfo.ticker[0].text}
            ticker1Numbers={siteInfo.ticker[0].numbers}
            ticker2Text={siteInfo.ticker[1].text}
            ticker2Numbers={siteInfo.ticker[1].numbers}
            ticker3Text={siteInfo.ticker[2].text}
            ticker3Numbers={siteInfo.ticker[2].numbers} />*/}
        </div>
        <Nav />
        <About
          title={siteInfo.about.title}
          alt={siteInfo.about.alt}
          text={siteInfo.about.text} />
        <Gallery 
          title={siteInfo.gallery.title}
          description={siteInfo.gallery.description}
          carousel={siteInfo.gallery.carousel}
          carouselText={siteInfo.gallery.carouselText}
          links={siteInfo.gallery.links} />
        <Responsibilities
          title={siteInfo.Responsibilities.title}
          alt={siteInfo.Responsibilities.alt}
          text={siteInfo.Responsibilities.text} />
        <Approach
          title={siteInfo.Approach.title}
          alt={siteInfo.Approach.alt}
          text={siteInfo.Approach.text} />
        <Integrations
          title={siteInfo.Integrations.title}
          text={siteInfo.Integrations.text}
          tabs={siteInfo.Integrations.tabInfo} 
          />
      </div>
    );
}

export default App;
