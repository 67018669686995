
import ownerImage from '../media/ownerImage.jpeg';
import carouselImage1 from "../media/1.jpg";
import carouselImage2 from '../media/2.jpg';
import carouselImage3 from '../media/3.jpg';
import carouselImage4 from '../media/4.jpg';
import carouselImage5 from "../media/5.jpg";
import carouselImage6 from '../media/6.jpg';
import carouselImage7 from '../media/7.jpg';
import carouselImage8 from '../media/8.jpg';
import carouselImage9 from "../media/9.jpg";
import carouselImage10 from '../media/10.jpg';
import carouselImage11 from '../media/11.jpg';
import carouselImage12 from '../media/12.jpg';
import carouselImage13 from "../media/13.jpg";
import carouselImage14 from '../media/14.jpg';

export const siteInfo = {
	header: {
    	alt: "ift logo"
	},
	heroAlt: "ift",
	about: {
    	title: "Summary",
    	text: [
        	"I joined IFT as an Integration Specialist and, within two years, I was promoted to Integrations Engineering Manager, leading my own team.",
        	"IFT’s fast-paced environment requires self-direction and a strong focus on delivering results.",
        	"During my time at IFT, I have elevated integrations and auxiliary systems into a vital, revenue-generating component of the business model.",
        	"In my leadership role, I emphasize the importance of self-reliance and capability, mentoring my team while providing them with the time and opportunities to learn and expand their skill sets."
    	]
	},
	Responsibilities: {
    	title: "Responsibilities",
    	text: [
        	"Develop payload systems that become standard production offerings.",
        	"Modernize aircraft capabilities, including integration of onboard computing, IP networking, and quick-release electrical/mechanical payload interfaces.",
        	"Develop and integrate systems such as ground control stations, radio systems, IP-based video streaming solutions, and auxilary systems such as precision GPS systems for RTK/PPK workflows.",
        	"I manage the development of software for our ground control stations and payload integrations.",
        	"Systems and embedded systems administration, including environment configuration, network and serial communication management, microcontroller configuration, scripting, and system monitoring. ",
        	"Set up test environments and processes to accelerate development iterations.",
                "Troubleshoot and optimize payload systems to ensure reliable operation.",
                "Design custom one-off payloads and auxiliary systems.",
                "Create documentation to enable production teams to manufacture systems designed by my team.",
                "Implement and maintain firmware control systems."
    	]
	},
	Approach: {
    	title: "Approach",
    	text: [
        	"An overview of my workflow during an integration.",
                "ASSESS: Identify and define user requirements to guide development.",
        	"SCOPE: Determine the hardware and software needed to meet those requirements.",
        	"PROTOTYPE: Prioritize rapid prototyping and rigorous testing to identify and address potential issues early in the development cycle.",
        	"ITERATION: Refine prototypes through iterative improvements to mechanical parts, software, and cabling to ensure reliability and manufacturability.",
        	"COLLABORATION: Work with in-house mechanical engineers to design physical components (e.g., housings and brackets) and guide software development with internal and external resources for both backend and frontend needs. ",
        	"DOCUMENTATION: Create comprehensive production and end-user documentation to support system deployment.",
                "HANDOFF: Oversee the transition from development to manufacturing, ensuring a seamless handoff between my engineering team and production."
    	]
	},
	Integrations: {
    	title: "Integrations",
    	text: [
        	"PAYLOADS:",
        	"Sony A7R",
        	"Sony LR1",
        	"Sony RX1R",
        	"Micasense Altum",
        	"Micasense RedEdge",
                "Micasense RedEdge Dual",
                "Gremsy Vio",
                "Sentera 65R",
                "Sentera 6X Multispectral",
        	"Workswell GIS 320 Radiometric",
        	"Workswell Enterprise",
        	"SPH Skyhub",
        	"SPH Methane Detector",
        	"SPH GPR",
                "SPH Magnetometer",
                "Nextvision Raptor & Trip6",
                "",
        	"AUXILARY:",
        	"Navigational RTK",
        	"Lightware range finder",
        	"Herelink tether RTSP server",
        	"Emlid RTK/PPK",
        	"Klau RTK/PPK",
                "Long range antenna kit",
                "Dual operator aircraft / payload control and video streaming",
                "",
        	"AIRCRAFT:",
        	"Network and payload interface panel and wiring harness",
        	"IP network integration",
        	"Mechanical and electrical payload quick disconnect system",
        	"RTSP FPV",
        	"F9P Zed flight GPS integration",
                "ModalAI Voxl2 SBC admin & config",
                "ModalAI Voxl 2 RTSP",
                "ModalAI Voxl 2 Mavlink Server",
        	"LEMO payload interface",
        	"Air Pixel Entire payload controller",
        	"",
        	"RADIOS:",
        	"Sylvus",
        	"Herelink",
                "Doodle labs",
                "RFD900",
                "Microhard",
        	"Taisync",
        	"",
        	"GROUND CONTROL STATIONS:",
        	"UXV SROC",
        	"Herelink",
        	"Vision2",
                "Aurora G7",
                "",
                "SOFTWARE:",
                "IFT specific fork of QGC, called IGC, for Android, Windows, Linux, Mac.",
                "IGC VIO RTSP feature",
                "IGC VIO controls feature",
                "IGC LR1 controls feature",
                "IGC Sentera controls feature",
                "IGC Payload detection service",
                "IGC Payload detection service",
                "IGC LR1 real time HD image inspection feature",
                "IGC LR1 firmware update feature",
                "IGC RC channel panel feature",
                "LR1 geotagging app for Windows, Linux, Android",
                "Mavlink to Sony SDK proxy",
                "USB camera RTSP service",
                "HDMI capture RTSP service",
                "Dronesense integration",
                "Aerodome platform",
                "",
                "CUSTOM",
                "Gremsy VIO & Scylla AI",
                "Dual payload (A7R & Altum) with Emlid RTK/PPK",
                "Dual payload (A7R & Altum) with Klau RTK/PPK",
                "Dual Payload (A7R & Altum) with Flight GPS’",
                "Voxl2 doodle VIO PC GCS",
                "Voxl2 doodle vision2 GCS",
                "Voxl2 doodle SROC GCS",
                "",
                "BENCH ENVIRONMENTS:",
                "800 standard test bench",
                "1200 standard test bench",
                "800 with onboard computing test bench",
                "Aerodome integration test bench",
                "Remote test bench access and network based power switching",
                "",
                "SYSTEMS & PROCESSES:",
                "Documentation for all integrations and systems",
                "Firmware control for Herelink, VIO, LR1, A7R, Workswell, Altum, Spinel FPV"

    	]
	},
	gallery: {
    	title: "Gallery",
    	description: "",
    	carousel: [carouselImage1, carouselImage2, carouselImage3, carouselImage4, carouselImage5, carouselImage6, carouselImage7, carouselImage8, carouselImage9, carouselImage10, carouselImage11, carouselImage12, carouselImage13, carouselImage14],
    	carouselText: [
        	"IF1200 Sony A7R, Micasense Altum and Emlid RTK/PPK",
        	"IF1200 Workswell GIS320 radiometric",
        	"IF800 Sony LR1",
        	"IF800 Gremsy Vio",
        	"IF800 Sentera 65R",
        	"IF1200 Skyub magnetometer ",
        	"IF800 Gremsy Vio",
        	"IF800 Sony LR1",
        	"IF800 Micasense Altum",
        	"IF1200 Sony A7R, Micasense Altum and Emlid RTK/PPK",
        	"IF800 Sentera 65R",
        	"IF800 Sentera 6X",
        	"IF1200 Skyhub GPR",
        	"IF1200 Skyhub magnetometer"
        	
    	],
    	links: ["/static/media/1.d97cf88bbb6a37ee395a.jpg",
    	"/static/media/2.18b247d7f23b45c23548.jpg",
    	 "/static/media/3.c68edaf980b25151ab7e.jpg",
    	  "/static/media/4.49a7e88fb2aa70195f05.jpg",
    	   "/static/media/5.062a478b5e71796a1b3e.jpg", 
    	   "/static/media/6.61021dbfcc52edebb461.jpg", 
    	   "/static/media/7.cfe2501b5faaeb1801f8.jpg", 
    	   "/static/media/8.0fc3e310d0b8479efe0c.jpg", 
    	   "/static/media/9.f2d48e3b95613b051b3a.jpg", 
    	   "/static/media/10.c6fede49e37600948db3.jpg", 
    	   "/static/media/11.ecb8738b7360925ff9f2.jpg", 
    	   "/static/media/12.32b1b9eee2747c554b78.jpg", 
    	   "/static/media/13.937259beb71c60521c0a.jpg", 
    	   "/static/media/14.5f2c8ac38eae21ba5727.jpg"]
	}
}

