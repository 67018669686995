import React, { useState } from 'react';

function Nav(){
    return <div className="nav-container">
        <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#summary">Summary</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#Responsibilities">Responsibilities</a></li>
            <li><a href="#Approach">Approach</a></li>
            <li><a href="#Integrations">Integrations</a></li>
        </ul>
    </div>
}

export default Nav;
